import React, {Component} from "react"
import TextField from "@material-ui/core/TextField";
import styled from "styled-components"
import IntlTelInput from 'react-intl-tel-input';

export default (props) => (
    <Text color={global.is_koolskools?'#55b9b1':global.colors[1]} className={props.className || ""}>
        {props.is_phone_number ?
        <>
            <label>{props.label}</label>
            <IntlTelInput inputClassName={""}
                preferredCountries={['ma']} 
                name="phone_number"
                defaultValue={props.phone_number || ""}
                onPhoneNumberChange={props.onChange}
            />
        </>
        :
        <TextField
                error={props.error}
                id="standard-error"
                type={props.type}
                label={props.label}
                className={props.className}
                margin="normal"
                name={props.name}
                value={props.value}
                onChange={props.onChange}
            />
        }
    </Text>
)
    

const Text = styled.div`
    .textInput > div:after, .textInput > div:before{
        border-bottom: 2px solid ${props => props.color}!important;
    }
`