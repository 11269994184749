import React, { Component } from "react";
import Modal from "react-responsive-modal";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";

import purple from "@material-ui/core/colors/purple";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";

import { resendActivationData } from "services/api";
import Radio from "@material-ui/core/Radio";
import FormLabel from "@material-ui/core/FormLabel";


import { Formik, Form, Field } from "formik";
import * as Yup from "yup";

import { loginProfessor } from "services/actions";
import { RegisterProfessor } from "../../../../services/actions";
import ModalCompteSuccess from "./ModalCompteSuccess";
import { globalData } from "services/cms/actions";
import {clear, get, set} from "services/storage"
import IntlTelInput from 'react-intl-tel-input';
import ModalPassword from "../../../children/home/components/ModalPassword";
import RegisterFormComponent from 'components/RegisterFormComponent'
import 'react-intl-tel-input/dist/main.css';
import { withTranslation, Trans } from 'react-i18next';
import localforage from 'localforage';

const theme = createMuiTheme({
  typography: {
    useNextVariants: true,
  },
  palette: {
    primary: { main: purple[500] }, // Purple and green play nicely together.
    secondary: { main: "#00b3a9" } // This is just green.A700 as hex.
  }
});

class ModalSigninMobile extends Component {

  constructor(props) {
    super(props);
    this.state = {
      success: false,
      selectedValue: "a",
      captcha: {
        min: Math.floor(Math.random() * 10),
        max: Math.floor(Math.random() * 10)
      },
      errorCaptcha: false,
      errorGlobal: "",
      server_errors: {},
      resend_activation_data: "",
      resend_activation_data_message: "",
      username: "",
      open: false,
      messageSuccess: "",
    };
    this.test = "mail";
  }

  componentWillReceiveProps(newProps){
    if(newProps.openProfessorMobile.signup && this.props.openProfessorMobile.popup !== newProps.openProfessorMobile.popup){
      this.setState({server_errors: {}});
    }
  }

  getValidationSchemaSignup = () => { 
    const usernameRegExp = /^[A-Za-z0-9]+(?:[_-][A-Za-z0-9]+)*$/;
    if(this.test === "phone"){
      const phoneRegExp = /^(\+212|0)([ \-_/]*)(\d[ \-_/]*){9}$/;
      return Yup.object().shape({
        firstName: Yup.string().default("")
          .required(this.props.t("First name required")),
        lastName: Yup.string().default("")
          .required(this.props.t("Last Name required")),
        username: Yup.string().default("")
          .required(this.props.t("Username required"))
          .matches(usernameRegExp, this.props.t('Username invalid')),
        password: Yup.string().default("")
        .required(this.props.t("Password required")),
        // .min(5, this.props.t("Password must be greater than 5 character")),
        phone: Yup.string()
        .matches(phoneRegExp, this.props.t('Phone format incorrect'))
        .when('email', {
            is: (email) => !email,
            then: Yup.string().required(this.props.t("Email or phone is required")+"!")
        }),
        accept: Yup.string()
          .required(this.props.t("You should Accept the Terms and Conditions")),
        captcha: Yup.string().default("")
          .required(this.props.t("Captcha required"))
      });
    }
    else{
      return Yup.object().shape({
        firstName: Yup.string().default("")
          .required(this.props.t("First name required")),
        lastName: Yup.string().default("")
          .required(this.props.t("Last Name required")),
        username: Yup.string().default("")
          .required(this.props.t("Username required"))
          .matches(usernameRegExp, this.props.t('Username invalid')),
        password: Yup.string().default("")
        .required(this.props.t("Password required")),
        // .min(5, this.props.t("Password must be greater than 5 character")),
        email: Yup.string()
          .email(this.props.t("Email format incorrect"))
          .when('phone', {
              is: (phone) => !phone,
              then: Yup.string().required(this.props.t("Email or phone is required")+"!")
          }),
        accept: Yup.string()
          .required(this.props.t("You should Accept the Terms and Conditions")),
        captcha: Yup.string().default("")
          .required(this.props.t("Captcha required"))
      });
    }
  };

  getValidationSchemaSignin = () => {
    return Yup.object().shape({
      username: Yup.string().default("")
        .required(this.props.t("Username required")),
      password: Yup.string().default("")
      .required(this.props.t("Password required"))
      // .min(5, this.props.t("Password must be greater than 5 character")),
    });
  };

  // handleOpenSuccess = () => {
  //   this.setState({
  //     success: true
  //   });
  // };

  // handleCloseSuccess = () => {
  //   this.setState({
  //     success: false,
  //     messageSuccess: ""
  //   });
  // };

  handleOpenSigninProfessorMobile = () => {
    this.props.handleOpenSigninProfessorMobile();
  };

  handleCloseProfessorMobile = () => {
    this.props.handleCloseProfessorMobile();
  };

  handleResendActivationData = () => {
    
    let account;
    account = {
      username: this.state.username
    };
    resendActivationData(account)
    .then(data => {
      this.setState({resend_activation_data_message: data.message});
    })
    .catch(err => {
      this.setState(state => ({
        errorGlobal: err.message
      }));
    });
  };

  handleSubmit = form => {
    if (this.props.openProfessorMobile.signin){
      let loginprof;
      loginprof = {
        username: form.username,
        password: form.password
      };
      
      var lev = get("level_groups");
      var current_language = get("current_language"),
        device_token = get("device_token");
      clear();
      localforage.clear()
      if(global.ws_notification)
        global.ws_notification.close();
      set("device_token", device_token);
      set('topics', {});
      set("level_groups", lev);
      set("current_language", current_language);
      return this.props
        .dispatch(loginProfessor(loginprof))
        .then(data => {
          if (data.payload.success) {
            const user = get("session_user");
            if(['pr','pr_pa'].indexOf(user.account_type) >= 0 && (user.schools || user.profile && user.profile.schools || []).length > 0){
              this.props.dispatch(globalData({}))
              this.props.history.push({
                pathname: "/professor/classes",
                state: { from_login: true }
              });
            }
            else 
              this.props.history.push("/");
          }
        })
        .catch(err => {
          var message = err.message || "";
          var resend_activation_data = message.indexOf("mail_not_yet_verified") !== -1 ? "mail" :(message.indexOf("phone_not_yet_verified") !== -1 ? "phone" : "");
          this.setState(state => ({
            errorGlobal: message.replace(",mail_not_yet_verified", "").replace(",phone_not_yet_verified", ""),
            resend_activation_data: resend_activation_data,
            resend_activation_data_message: "",
            username: form.username
          }));
        });
      }
    else{
      if (form.captcha == this.state.captcha.min + this.state.captcha.max) {
        let register;
        register = {
          username: form.username,
          password: form.password,
          first_name: form.firstName,
          last_name: form.lastName,
          mail_or_phone: form.test,
          gender: form.gender
        };

        if(this.props.schoolProfessorKey)
          register.scPr_key = this.props.schoolProfessorKey;

        if(form.test == "mail"){
          register.email = form.email;
        }
        else{
          register.phone_number = form.phone;
        }
        this.setState({server_errors: {}});
        return this.props
          .dispatch(RegisterProfessor(register))
          .then(data => {
            if (data.payload.success) {
              if(data.payload.must_login){
                this.props.handleOpenSigninProfessorMobile()
              }
              else{
                this.handleCloseProfessorMobile();
                this.props.handleOpenSuccess(`${this.test === "phone" ? this.props.t("An SMS has been sent to you activate your account")+"." : this.props.t("An Email has been sent to you activate your account")+"."}`, this.test === "phone", form.phone);
                // setTimeout(() => {
                //   this.handleOpenSuccess();
                // }, 10);
              }
            }
            else{
              var server_errors = {};
              Object.keys(data.payload).map(key => {
                server_errors[key] = data.payload[key][0].user_message;
              });
              this.setState({server_errors: server_errors});
            }
          })
          .catch(err => {
            console.log(err);
            this.setState({
              errorCaptcha: false,
              errorGlobal: err.message,
              captcha: {
                min: Math.floor(Math.random() * 10),
                max: Math.floor(Math.random() * 10)
              }
            });
          });
      } else {
        this.setState({
          errorCaptcha: true,
          captcha: {
            min: Math.floor(Math.random() * 10),
            max: Math.floor(Math.random() * 10)
          }
        });
      }
    }
  };

  onOpenModalPassword = () => {
    this.setState({
      open: true
    });
  };

  onCloseModalPassword = () => {
    this.setState({
      open: false
    });
  };

  handlePasswordReseted = (message) => {
    this.handleCloseProfessorMobile();
    this.props.handleOpenSuccess(message);
    this.setState({
      // success: true,
      // messageSuccess: message,
      open: false
    });
  };

  handleOpenSignupProfessorMobile = () => {
    this.setState({
      captcha: {
        min: Math.floor(Math.random() * 10),
        max: Math.floor(Math.random() * 10)
      }
    });
    this.props.handleOpenSignupProfessorMobile();
  };
  
  render() {
    const {server_errors, resend_activation_data, resend_activation_data_message} = this.state;
    const {professor} = this.props;
     /* TODO: TRANSLATIONS */
    return (
      <>
      <Modal
        open={
          this.props.openProfessorMobile && this.props.openProfessorMobile.popup
        }
        onClose={this.handleCloseProfessorMobile}
        showCloseIcon={false}
        center
        classNames={{
          overlay: { dialogOverlay: "white", dialogMobile: "white" },
          modal: {
            dialog: "white",
            dialogConnexion: "white",
            dialogAddSchool: "white",
            dialogRegister: "white"
          }
        }}
      >
        <div
          className="closeRegister"
          onClick={() => this.props.handleCloseProfessorMobile()}
        >
          &times;
        </div>
        <div className="dialog-top">
          <a
            className={this.props.openProfessorMobile.signin && "active"}
            onClick={() => this.props.handleOpenSigninProfessorMobile()}
          >
            { this.props.t('CONNECTION') }
          </a>
          <a
            onClick={() => this.handleOpenSignupProfessorMobile()}
            className={!this.props.openProfessorMobile.signin && "active"}
          >
            { this.props.t('register') }
          </a>
        </div>
        <div className="dialog-content">

          {this.props.openProfessorMobile.signin ? (
            <>
            <Formik
              initialValues={{
                username: this.props.username || "",
                password: ""
              }}
              validationSchema={this.getValidationSchemaSignin}
              onSubmit={values => {
                this.handleSubmit(values);
              }}
            > 
              {({ errors, touched, values, handleChange }) => {
              return (
                <Form>
                  <div className="modalConnexionForm">
                    {this.props.account_activated &&
                      <div className="account_activated">Bienvenue sur Koolskools. Votre compte a été activé, vous pouvez maintenant accéder à votre espace en saisissant votre mot de passe.</div>
                    }
                    {this.state.errorGlobal &&
                    <div className="modalConnexion">
                      <div className="modalConnexionTop">
                          <div className="textErrors">{this.state.errorGlobal}</div>
                      </div>
                    </div>
                    }

                    <div className="connexionLigne w12">
                      <label>{ this.props.t('Enter Username') }</label>
                      <input type="text"
                        className={`textInput ${errors.username && "textInputError"}`}
                        name="username"
                        value={values.username || this.props.username || ""}
                        onChange={handleChange}
                      />
                      {errors.username && touched.username ? (
                        <div className="textErrors">{errors.username}</div>
                      ) : null}
                    </div>                    

                    <div className="connexionLigne w12">
                      <label>{ this.props.t('Password') }</label>
                      <input type="password"
                        className={`textInput ${errors.password && "textInputError"}`}
                        name="password"
                        value={values.password}
                        onChange={handleChange}
                      />
                      {errors.password && touched.password ? (
                        <div className="textErrors">{errors.password}</div>
                      ) : null}
                    </div>
                    <div className="connexionLigne w12">
                      <a onClick={() => this.onOpenModalPassword()} className="forgot">{ this.props.t('FORGOT PASSWORD?') }</a>
                      <button type="submit" className="signin">
                      { this.props.t('SIGN IN') } 
                      </button>
                    </div>
                    {resend_activation_data &&
                    <>
                      <div className="connexionLigne w12 resend_activation_data">
                      { this.props.t('Resend') } {resend_activation_data == "mail" ? "link " : "code "} activation?
                        <button className="resend_activation_data_btn" onClick={this.handleResendActivationData}>{ this.props.t('Resend') }</button>
                      </div>
                      {resend_activation_data_message &&
                        <div className="resend_activation_data_message">
                          {resend_activation_data_message}
                        </div>
                      }
                    </>
                    }
                  </div>
                </Form>);
              }}
            </Formik>
            </>
          ) : (
            <RegisterFormComponent 
              pages="professor"
              handleOpenSuccess={(msg) => {
                this.props.handleCloseProfessorMobile();
                this.props.handleOpenSuccess(msg);
              }}
              handleOpenTermsAndCondition={this.props.handleOpenTermsAndCondition}
            />
          )}
        </div>
      </Modal>

      {this.state.open && (
        <ModalPassword
          open={this.state.open}
          onCloseModalPassword={this.onCloseModalPassword}
          handlePasswordReseted={(message) => this.handlePasswordReseted(message)} />
      )} 
      </>
    );
  }
}

const mapState2Props = state => ({ session: state.session });
export default connect(mapState2Props)(withRouter(withTranslation('translations')(ModalSigninMobile)));