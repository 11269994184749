import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter, Redirect } from "react-router-dom";

import styled from "styled-components";

import { Formik, Form } from "formik";
import * as Yup from "yup";

import LdsSpinner from 'components/LdsSpinner';

import {clear} from "services/storage";

import { set, get } from "services/storage";
import { withTranslation, Trans } from 'react-i18next';

import { loginProfessor } from "services/actions";
import { globalData } from "services/cms/actions";

import { sanady_unconnected_api_get } from "services/cms/api";
import ModalPassword from "pages/children/home/components/ModalPassword"
import ModalProfessorSanadiRegister from "pages/professor/home/components/ModalProfessorSanadiRegister"
import ModalCompteSuccess from "pages/children/home/components/ModalCompteSuccess";
import MediaQuery from "react-responsive";

import {videos} from './_resources'
import ModalPasswordChanged from "../professor/home/components/ModalPasswordChanged";
import {forgotPassword} from "services/api";
import localforage from 'localforage';

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      open_register_professor_sanadi: false,
      openConnexion: false,
      success: false,
      messageSuccess: "",
      section: 0,
      resend_activation_data: "",
      resend_activation_data_message: "",
      programs_projects_schools: {},
      isSubmitting: false,
      reset_password_response: "",
      reset_password_error: ""
    }

    const query = new URLSearchParams(props.location.search);

    if(query.get('reset_password')){
      var openConnexion = true;
      var data_ = {
        key: query.get('key'),
        id: query.get('id'),
        from: 'koolskools'
      };
      if(query.get('is_email')){
        data_.is_email = true;
      }
      if(global.is_laraki_logo)
        data_.from = 'elaraki';
      else if(global.is_sanady_logo)
        data_.from = 'sanady';
      else if(global.is_pont_logo)
        data_.from = 'pont';
      else if(global.is_reference_logo)
        data_.from = 'reference';
      else if(global.is_yakada_logo)
        data_.from = 'yakada';
      else if(global.is_kastalani_logo)
        data_.from = 'kastalani';
      else if(global.is_fmps_logo)
        data_.from = 'FMPS';
      forgotPassword(data_).then(data => {
        this.setState({reset_password_response: data.response_msg, reset_password_error: data.error});
      })
      .catch(error => {
      });
    }
  }

  componentDidMount(){
    if(global.is_sanady_logo){
      this.getProgramsProjectsSchools();
    }
  }

  onOpenModalPassword = () => {
    this.setState({
      open: true
    });
  };
  onOpenModalProfessorSanadiRegister = () => {
    this.setState({
      open_register_professor_sanadi: true
    });
  };
  onCloseModalProfessorSanadiRegister = () => {
    this.setState({
      open_register_professor_sanadi: false
    });
  };

  onCloseModalPassword = () => {
    this.setState({
      open: false
    });
  };

  handlePasswordReseted = (message) => {
    this.setState({
      success: true,
      messageSuccess: message,
      open: false
    });
  };

  handleCloseSuccess = () => {
    this.setState({
      success: false,
      messageSuccess: ""
    });
  };

  getValidationSchema = () => {
    return Yup.object().shape({
      username: Yup.string().required(this.props.t("Username required")),
      password: Yup.string()
        // .min(5, this.props.t("Password must be greater than 5 character"))
        .required(this.props.t("Password required"))
    });
  };

  handleSubmit = form => {
    const {section} = this.state;
    let loginprof = this.props.addedData || {};
    if(this.props.addedData){
      loginprof.added_action = "add_school";
    }
    loginprof.username = form.username;
    loginprof.password = form.password;
    if(section == 3)
      loginprof.page_front = 'parent';
    
    var lev = get("level_groups");
    var current_language = get("current_language"),
      device_token = get('device_token');
    clear();
    localforage.clear();
    if(global.ws_notification)
      global.ws_notification.close();
    set("device_token", device_token);
    set("level_groups", lev);
    set("current_language", current_language);
    return this.props
      .dispatch(loginProfessor(loginprof))
      .then(data => {
        const user = get("session_user");
        if (data.payload.success) {
          if(section === 2){
            if(user.profile.is_escort){
              this.props.history.push("/escort/transport");
            }
            else{
              if(!user.profile.is_school_admin)
                this.props.dispatch(globalData({}));
              this.props.history.push("/");
            }
          }else if (section === 3) {
            if(['pa', 'pr_pa'].indexOf(user.account_type) >= 0 || user.account_type.indexOf('pa') != -1){
              this.props.history.push("/parents/dashboard");
            }
            else if(user.profile.is_escort){
              this.props.history.push("/escort/transport");
            }
            else if(this.props.key_inv){
              this.props.dispatch(globalData({}));
              this.props.history.push("/parents/dashboard?key_inv=" + this.props.key_inv);
            }
            else{
              if(['pa', 'pr_pa'].indexOf(user.account_type) >= 0){
                this.props.dispatch(globalData({}));
                this.props.history.push("/parents/dashboard");
                // else
                //   this.props.history.push("/parents/candidatures");
              }
              else
                this.props.history.push("/");
            }
          }else if (section === 1) {
            if(['pr', 'pr_pa'].indexOf(user.account_type) >= 0 && (user.schools || user.profile && user.profile.schools || []).length > 0){
              this.props.dispatch(globalData({}));
              this.props.history.push("/professor/classes");
            }
            else if(user.profile.is_escort){
              this.props.history.push("/escort/transport");
            }
            else if(user.profile.is_school_admin){
              this.props.history.push("/");
            }
            else if(this.props.key_inv){
              this.props.dispatch(globalData({}));
              this.props.history.push("/parents/dashboard?key_inv=" + this.props.key_inv);
            }
            else if(['pa', 'pr_pa'].indexOf(user.account_type) >= 0){
              this.props.history.push("/parents/dashboard");
            }
            else{
              this.props.history.push("/");
            }
          } else {
            if(user.profile.is_escort){
              this.props.history.push("/escort/transport");
            }
            else if(['pr','pr_pa'].indexOf(user.account_type) >= 0 && (user.schools || user.profile && user.profile.schools || []).length > 0){
              this.props.dispatch(globalData({}));
              this.props.history.push("/professor/classes");
            }
            else
              this.props.history.push("/");
          }
        }
        
      })
      .catch(err => {
        var message = err.message || "";
        this.setState(state => ({
          errorGlobal: message.replace(",mail_not_yet_verified", "").replace(",phone_not_yet_verified", ""),
          username: form.username,
          isSubmitting: false
        }));
      });
  }

  render_connexion = () => {
    const {isSubmitting, section} = this.state;
    return <div className="modalConnexion">
    <div className="modalConnexionTop">
      <Formik
        initialValues={{
          username: this.props.username || this.state.username || "",
          password: ""
        }}
        validationSchema={this.getValidationSchema}
        onSubmit={values => {
          this.setState({
            isSubmitting:true
          })
          this.handleSubmit(values);
        }}
      > 
        {({ errors, touched, values, handleChange }) => {
          return (<Form className="d-flex flex-column align-items-center">
            {this.state.errorGlobal && (                    
              <div style={{color: 'red'}}>{this.state.errorGlobal}</div>
            )}
           
          <div className="">
            <div className="form-connexion">
              <div className="form-item">
                <input type="text"
                  className={`textInput ${errors.username && "textInputError"}`}
                  name="username"
                  value={values.username}
                  onChange={handleChange}
                />
                {errors.username && touched.username ? (
                  <div className="textErrors">{errors.username}</div>
                ) : null}
              </div>
              <div className="form-item">
                <input type="password"
                  className={`textInput ${errors.password && "textInputError"}`}
                  name="password"
                  value={values.password}
                  onChange={handleChange}
                />
                {errors.password && touched.password ? (
                  <div className="textErrors">{errors.password}</div>
                ) : null}
              </div>
            </div>
            <div className="form-footer">
              <div className='d-flex'>
                {isSubmitting && <LdsSpinner type='relative'/>}
                <button type="submit" className="signin" 
                  style={{float:'none', background: `linear-gradient(to bottom,${global.colors[0]} 0%,${global.colors[1]} 100%)`}} 
                  disabled={isSubmitting}>
                { this.props.t('SIGN IN') } 
                </button>
              </div>
              <a onClick={() => this.onOpenModalPassword()} className="forgot" style={{float:'none'}}>
                { this.props.t('FORGOT PASSWORD?') }
              </a>
              {(global.is_sanady_logo && section == 1) &&
                <a onClick={() => this.onOpenModalProfessorSanadiRegister()} className="register" style={{float:'none'}}>
                  {/* { this.props.t("Don't have an account? Create one.") } */}
                  { this.props.t("Vous n'avez pas de compte ? Créez-en un.") }
                </a>
              }
            </div>
          </div>
        </Form>);
        }}
      </Formik>
    </div>
  </div>
  }

  getProgramsProjectsSchools = () => {
    var data = {
      action: "get_programs_projects_schools",
    };
    sanady_unconnected_api_get(data).then(res => {
      if(res.success){
        this.setState({programs_projects_schools: res.programs_projects_schools || {}});
      }

    }).catch(err => {
      console.log(err);
    })
  }

  render() {
    const {section} = this.state;
    return (
      <Children>
        <section style={{overflow: 'hidden'}}>
          <MediaQuery query="(min-width: 1200px)">
            <div className="row">
              <div className="col-8 d-flex justify-content-center align-items-center" style={{overflow: 'hidden'}}>
                {global.is_laraki_logo?
                <img src={videos.home_laraki} style={{height: '80vh'}}/>
                :
                <video style={{height: '100vh'}}  loop autoPlay muted>
                    <source src={global.is_fmps_logo?videos.video_fmps:videos.video} type='video/mp4'/>
                    Your browser does not support the video tag.
                </video>
                }
              </div>
              <div className="col-4">
                <div style={{height: '100vh'}} className="d-flex flex-column justify-content-center align-items-center">
                  {!global.is_laraki_logo &&
                  <img src={global.logo} style={{position:'absolute', width: 150, top: 20}}/>
                  }
                  <div className={'header-menu'}>
                    <button className={`${section == 0?'active':''}`}
                      onClick={() => section != 0?this.setState({section: 0}):{}}>
                      élèves
                      {section == 0?
                      <span></span>
                      :<></>
                      }
                    </button>
                    <button className={`${section == 1?'active':''}`}
                      onClick={() => section != 1?this.setState({section: 1}):{}}>
                      enseignants
                      {section == 1?
                      <span></span>
                      :<></>
                      }
                    </button>
                    <button className={`${section == 2?'active':''}`}
                      onClick={() => section != 2?this.setState({section: 2}):{}}>
                      écoles
                      {section == 2?
                      <span></span>
                      :<></>
                      }
                    </button>
                    <button className={`${section == 3?'active':''}`}
                      onClick={() => section != 3?this.setState({section: 3}):{}}>
                      parents
                      {section == 3?
                      <span></span>
                      :<></>
                      }
                    </button>
                  </div>
                  {this.render_connexion()}
                  
                </div>
              </div>
            </div>
          </MediaQuery>
          <MediaQuery query="(max-width: 1200px)">
          {this.state.openConnexion ?
          <div className="modal-connexion">
            <div className="header">
              <div>
                <img src={global.logo} style={{width: 40}}/>
              </div>
              <div className="btn-close"
                onClick={() => this.setState({openConnexion: false})}>
                <i className="fa fa-times"/>
              </div>
            </div>
            <div className={'header-menu'}>
              <button className={`${section == 0?'active':''}`}
                onClick={() => section != 0?this.setState({section: 0}):{}}>
                élèves
                {section == 0?
                <span></span>
                :<></>
                }
              </button>
              <button className={`${section == 1?'active':''}`}
                onClick={() => section != 1?this.setState({section: 1}):{}}>
                enseignants
                {section == 1?
                <span></span>
                :<></>
                }
              </button>
              <button className={`${section == 2?'active':''}`}
                onClick={() => section != 2?this.setState({section: 2}):{}}>
                écoles
                {section == 2?
                <span></span>
                :<></>
                }
              </button>
              <button className={`${section == 3?'active':''}`}
                onClick={() => section != 3?this.setState({section: 3}):{}}>
                parents
                {section == 3?
                <span></span>
                :<></>
                }
              </button>
            </div>
            {this.render_connexion()}
          </div> 
          :
          <>
              {global.is_laraki_logo?
              <>
                <img src={videos.home_laraki} style={{width: '100%'}}/>
                <div className="signin" onClick={() => this.setState({openConnexion: true})}
                  style={{background: `linear-gradient(to bottom,${global.colors[0]} 0%,${global.colors[1]} 100%)`, margin: '40px auto'}}>
                  JE ME CONNECTE
                </div>
              </>
              :
              <>
                <video style={{height: '100vh'}}  loop autoPlay muted>
                    <source src={global.is_fmps_logo?videos.video_fmps:videos.video} type='video/mp4'/>
                    Your browser does not support the video tag.
                </video>
                <div className="open-connexion">
                  <img src={global.logo} style={{width: 150, top: 10}}/>
                  <div className="signin" onClick={() => this.setState({openConnexion: true})}
                    style={{background: `linear-gradient(to bottom,${global.colors[0]} 0%,${global.colors[1]} 100%)`}}>
                    JE ME CONNECTE
                  </div>
                </div>
              </>
              }
            </>
          }
          </MediaQuery>
        </section>
        <ModalPassword 
            open={this.state.open} 
            pages={section == 0?"children":(section == 2?"school":"")} 
            onCloseModalPassword={this.onCloseModalPassword}
            handlePasswordReseted={(message) => this.handlePasswordReseted(message)} />
        <ModalProfessorSanadiRegister 
            open={this.state.open_register_professor_sanadi} 
            onCloseModal={this.onCloseModalProfessorSanadiRegister}
            programs_projects_schools={this.state.programs_projects_schools}
            handleAccountCreated={(message) => {
              this.setState({
                open_register_professor_sanadi: false, 
                creation_account_message: message,
              })
            }} />
        <ModalCompteSuccess
            success={this.state.success}
            message={this.state.messageSuccess}
            handleCloseSuccess={this.handleCloseSuccess}
          />
        <ModalPasswordChanged
          success={
            this.props.location &&
            this.props.location.state &&
            this.props.location.state.password_changed
          }
          reset_password_response={this.state.reset_password_response}
          reset_password_error={this.state.reset_password_error}
          handleClose={() => {
            this.setState({reset_password_response: "", reset_password_error: ""});
            const state = { ...this.props.history.location.state };
            delete state.password_changed;
            this.props.history.replace({
              ...this.props.history.location,
              state
            });
          }}
        />
      </Children>
    );
  }
}
const Children = styled.div`
  section {
    background: radial-gradient(circle, rgba(255,255,255,1) 45%, rgba(245,250,252,1) 83%);
    height: 100vh;
  }
  .open-connexion{
    width: 100%;
    height: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    box-shadow: 6px 12px 33px 0 rgba(204, 235, 248, 0.19);
    background-color: #fff;
    position: absolute;
    bottom: 0px;
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
  }
  .modal-connexion{
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    .header-menu{
      width: 100%;
      padding: 0 20px;
    }
    .header{
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 20px;
      margin-bottom: 20px;
      .btn-close{
        width: 40px;
        height: 40px;
        flex-grow: 0;
        padding: 10px;
        border-radius: 20px;
        box-shadow: 6px 12px 33px 0 rgba(255, 224, 60, 0.16);
        background-color: rgba(218, 218, 218, 0.35);
        display: flex;
        align-items: center;
        justify-content: center;
        color:  #7d7d7d;
        font-size: 20px;
      }
    }
  }
  .header-menu{
    padding: 0;
    display: flex;
    align-self: center;
    justify-content: space-between;
    margin-bottom: 40px;
    width: 400px;
    >button{
      text-transform: capitalize;
      color: #6e7b81;
      background: none;
      display: flex;
      flex-direction: column;
      align-items: center;
      &.active{
        font-weight: bold;
        color: #042a45;
        span{
          background: #64c2be;
          width: 4px;
          height: 4px;
          display: block;
          border-radius: 50%;
        }
      }

    }
  }
  .form-connexion{
    display: flex;
    flex-direction: column;
    align-items: center;
    .form-item{
      input{
        width: 284px;
        height: 44px;
        flex-grow: 0;
        margin: 10px auto;
        padding: 11px 16px;
        border-radius: 12px;
        border: solid 0.3px #d4d3d3;
        background-color: #fafafa;
      }
    }
  }
  .form-footer{
    display: flex;
    flex-direction: column;
    align-items: center;
    .forgot{
      margin: 14px auto 32px auto;
      font-size: 11px;
      line-height: 2;
      color: #bbc3cc;
    }
    .register{
      margin: 14px auto 32px auto;
      font-size: 11px;
      line-height: 2;
      color: #bbc3cc;
    }
  }

  .signin{
    width: 276px;
    height: 56px;
    flex-grow: 0;
    margin: 22px 42px 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 26px;
    font-size: 16px;
    font-weight: 500;
    line-height: 1.38;
    letter-spacing: 1px;
    color: #fff;
    border: none;
  }
`;
const mapState2Props = state => ({ session: state.session });
export default connect(mapState2Props)(withRouter(withTranslation('translations')(Home)));
