import React, { Component, Suspense, lazy } from "react";
import { Provider } from "react-redux";
import { BrowserRouter, Route, Switch } from "react-router-dom";


import store from "./services/index";


// importation of school
import Home2 from "./pages/home2"; 
import Home from "./pages/children/home";
import HomeSchool from "./pages/school";
import HomeProfessor from "./pages/professor/home";
import HomeParent from "./pages/parents";

import LoadingAssets from './components/LoadingAssets';


import ErrorComponent from './pages/ErrorComponent'
import logo from "./assets/img/logo.svg";
import logo_laraki from "./assets/img/logo_laraki.png";
import logo_sanady from "./assets/img/logo_sanady.png";
import logo_fmps from "./assets/img/logo_fmps.png";
import logo_pont from "./assets/img/logo_pont.png";
import logo_reference from "./assets/img/logo_reference.png";
import logo_yakada from "./assets/img/logo_yakada.png";
import logo_kastalani from "./assets/img/logo_kastalani.png";


import "./assets/sass/global.scss";
import "coverflow-reactjs/lib/style.css";
import { set, get } from "./services/storage";
import {levelGroups} from "services/api"
// import {get_instanceID_info} from "./services/cms/api"

//Parents

import RouteSystem from "./routes";
import { withTranslation, Trans } from "react-i18next";

// monitoring

import { messaging } from "./init_fcm";
// import axios from "axios";

const ParentRoute = lazy(() => import('./routes/ParentRoute'));
const ChildRoute = lazy(() => import('./routes/ChildRoute'));
const SchoolRoute = lazy(() => import('./routes/SchoolRoute'));
const EscortRoute = lazy(() => import('./routes/EscortRoute'));
const UnconnectedRoute = lazy(() => import('./routes/UnconnectedRoute'));
const ProfessorRoute = lazy(() => import('./routes/ProfessorRoute'));
const AdminRoute = lazy(() => import('./routes/AdminRoute'));
const PilotageRoute = lazy(() => import('./routes/PilotageRoute'));

class App extends Component {

  constructor(props){
    super(props);
    global.is_laraki_logo = window.location.hostname == "www.gselaraki.com"
                            || window.location.hostname == "www.elarakiapp.com"   
                            || window.location.hostname == "elarakiapp.com" 
                            || window.location.hostname == "dev.gselaraki.com";
    global.is_sanady_logo = window.location.hostname == "www.sanady.koolskools.com"
                              || window.location.hostname == "sanady.koolskools.com"
                              || window.location.hostname == "devsanady.koolskools.com";
    
    global.is_pont_logo = window.location.hostname == "www.lepontapp.com"
                              || window.location.hostname == "lepontapp.com"
                              || window.location.hostname == "dev.lepontapp.com/";
    
    global.is_fmps_logo = window.location.hostname == "www.fmps-kids-learning.com"
                              || window.location.hostname == "fmps-kids-learning.com";
    
    global.is_reference_logo = window.location.hostname == "www.reference-net.com"
                              || window.location.hostname == "reference-net.com";
    
    global.is_yakada_logo = window.location.hostname == "www.elyakada-app.com"
                              || window.location.hostname == "elyakada-app.com";

    global.is_kastalani_logo = window.location.hostname == "www.alkastalani.com"
      || window.location.hostname == "alkastalani.com";
    
    global.is_koolskools = !global.is_sanady_logo && !global.is_laraki_logo && !global.is_pont_logo 
                        && !global.is_fmps_logo && !global.is_reference_logo && !global.is_yakada_logo 
                        && !global.is_kastalani_logo;
    global.logo = logo;
    global.colors = []
    if(global.is_laraki_logo){
      let link = document.querySelector("link[rel~='icon']");
      link.href = '/favicon2.ico';
      document.querySelector("head title").textContent = "Elaraki International School of Morocco";
      global.logo = logo_laraki;
      global.colors = ['#7581b7', '#2d408f']
    }
    else if(global.is_sanady_logo){
      let link = document.querySelector("link[rel~='icon']");
      link.href = '/favicon_sanady.ico';
      document.querySelector("head title").textContent = "Fondation Sanady";
      global.logo = logo_sanady;
      global.colors = ['#ffd7c1', '#ff5a00']
    }
    else if(global.is_fmps_logo){
      let link = document.querySelector("link[rel~='icon']");
      link.href = '/favicon_fmps.ico';
      document.querySelector("head title").textContent = "FMPS KIDS LEARNING";
      global.logo = logo_fmps;
      global.colors = ['#edc7a3', '#f49235']
    }
    else if(global.is_pont_logo){
      let link = document.querySelector("link[rel~='icon']");
      link.href = '/favicon_pont.ico';
      document.querySelector("head title").textContent = "Le pont";
      global.logo = logo_pont;
      global.colors = ['#8cd0df', '#2aa8c4']
    }
    else if(global.is_reference_logo){
      let link = document.querySelector("link[rel~='icon']");
      link.href = '/favicon_reference.ico';
      document.querySelector("head title").textContent = "LA RÉFÉRENCE";
      global.logo = logo_reference;
      global.colors = ['#8cd0df', '#2aa8c4']
    }
    else if(global.is_yakada_logo){
      let link = document.querySelector("link[rel~='icon']");
      link.href = '/favicon_yakada.ico';
      document.querySelector("head title").textContent = "El Yakada";
      global.logo = logo_yakada;
      global.colors = ['#a3e2ff', '#2ea9e2']
    }
    else if(global.is_kastalani_logo){
      let link = document.querySelector("link[rel~='icon']");
      link.href = '/favicon_kastalani.ico';
      document.querySelector("head title").textContent = "Al Imam al Kastalani";
      global.logo = logo_kastalani;
      global.colors = ['#a8c2df', '#11447b']
    }
    
    // axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';
//     let xmls=`<soapenv:Envelope xmlns:soapenv="http://schemas.xmlsoap.org/soap/envelope/" xmlns:web="https://www.kiosque-edu.com/webservices">
//               <soapenv:Header/>
//                 <soapenv:Body>
//                   <web:UserRessourcesCatalog>
//           <input>
//              <Cle>d02d3eec751d87336923a5e85e3161c7</Cle>
//              <Pf>Koolskools</Pf>
//              <ENTPersonStructRattachUAI>ecprodV9</ENTPersonStructRattachUAI>
//              <ENTPersonProfils>National_ENS</ENTPersonProfils>
//              <EnfantId></EnfantId>
//              <ENTEleveMEF></ENTEleveMEF>
//              <ENTEleveCodeEnseignements></ENTEleveCodeEnseignements>
//              <ENTEleveClasses></ENTEleveClasses>
//              <ENTAuxEnsClassesMatieres></ENTAuxEnsClassesMatieres>
//              <user>recette2021</user>
//              <ENTAuxEnsGroupes></ENTAuxEnsGroupes>
//              <ENTAuxEnsClasses>Classe C|Classe D</ENTAuxEnsClasses>
//              <ENTAuxEnsMEF></ENTAuxEnsMEF>
//              <ENTStructureTypeStruct></ENTStructureTypeStruct>
//           </input>
//        </web:UserRessourcesCatalog>
//     </soapenv:Body>
//  </soapenv:Envelope>`;

//       axios.post('https://www.kiosque-edu.com/webservices',
//            xmls,
//            {
//             /*headers:
//              {
//               //  'Content-Type': 'text/xml ; charset utf-8',
//               //  "Access-Control-Allow-Origin": "*",
//               //  "Access-Control-Allow-Methods": 'POST, GET, OPTIONS, PUT, DELETE',
//               //  'Access-Control-Allow-Headers': 'Content-Type, X-Auth-Token, Origin',
//               //  'User-Agent':'PostmanRuntime/7.28.0'
//             }*/
//            }).then(res=>{
//              console.log(res);
//            }).catch(err=>{console.log(err)});
  if(!get("level_groups")){
    levelGroups()
  }
  }
  
  registerPushListener = () => {
    if(messaging){
      // messaging.onBackgroundMessage = payload => {
      //   console.log(payload)
      // }
      messaging.onMessage(function(payload) {
        // console.log("111")
        // console.log("Message received. ", payload);
        // alert(payload.notification.body)
      //   if(remoteMessage.data.type){
      //     if(remoteMessage.data.type == 'new_student_recovery'){
            
      //       if(global.user.profile.is_school_admin && remoteMessage.data.to_admin){
      //         // if(this.state.current_idx == 0)
      //         EventRegister.emit('fetch_localisations')
      //       // else
      //       //   this.setState({
      //       //     localisations_count: this.state.localisations_count + 1
      //       //   },() => AsyncStorage.setItem('localisations_count', JSON.stringify(this.state.localisations_count)) )
      //       }
      //       else if(!global.user.profile.is_school_admin && !remoteMessage.data.to_admin ){
      //         EventRegister.emit('fetch_localisations', remoteMessage.data)
      //       }
              
      //     }
      //     // else if(remoteMessage.data.type == 'inbox'){
      //     //   if(this.state.current_idx == 4)
      //     //     EventRegister.emit('fetch_inbox')
      //     //   else
      //     //     this.setState({
      //     //       inbox_count: this.state.inbox_count + 1
      //     //     },() => AsyncStorage.setItem('inbox_count', JSON.stringify(this.state.inbox_count)) )
      //     // }
      //   }
      });
    }
  }

  async componentDidMount(){
    if(messaging){
      messaging
          .requestPermission()
          .then(async function() {
            const token = await messaging.getToken();
            set('device_token', token);
            // get_instanceID_info(token).then(res => {
            //   set('topics', res.rel.topics);
            // }).catch(err => {
            //   set('topics', []);
            // })

          })
          .catch(function(err) {
            console.log("Unable to get permission to notify.", err);
          });
      this.registerPushListener();
    }


  }
  render() {
    return (
      <Provider store={store}>
        <BrowserRouter>
          <Suspense fallback={<LoadingAssets/>}>
            <Switch>
              <ChildRoute path="/child"/>
              <ParentRoute path="/parents"/>
              <SchoolRoute path="/school"/>
              <EscortRoute path="/escort"/>
              <UnconnectedRoute path="/unconnected"/>
              <ProfessorRoute path="/professor"/>
              <AdminRoute path="/admin"/>
              <PilotageRoute path="/pilotage"/>
              {/* <Route
                exact
                path="/activate/:key"
                render={props => (
                  <ActivateAccount {...props} type="activate_account" />
                )}
              />
              <Route
                exact
                path="/sc-pr/activate/:key"
                render={props => (
                  <ActivateAccount {...props} type="activate_professor_account" />
                )}
              />
              <Route
                exact
                path="/invitation/:type/:key/:parent_id/:action"
                render={props => <ChildInvitation {...props} />}
              /> */}
              <Route
                exact
                path="/ErrorPage"
                render={props => <ErrorComponent {...props} />}
              />
            
              {!global.is_koolskools && <RouteSystem.AppHomeRoute path='*' exact component={Home2} />}
              
              {global.is_koolskools && <RouteSystem.AppHomeRoute  exact path="/home/parent" component={HomeParent} />}

              {global.is_koolskools && <RouteSystem.AppHomeRoute  exact path="/home/school" component={HomeSchool} />}

              {global.is_koolskools && <RouteSystem.AppHomeRoute  exact path="/home/professor" component={HomeProfessor} />}

              {global.is_koolskools && <RouteSystem.AppHomeRoute path='*' exact component={Home} />}

            </Switch>
          </Suspense>
        </BrowserRouter>
      </Provider>
    );
  }
}

export default withTranslation("translations")(App);
