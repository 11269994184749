import React, { Component } from "react";
import styled from "styled-components";

import Header from "../children/home/components/Header";
import Footer from "../children/home/components/footer";
import Carousel from "../professor/home/components/Carousel";
import BlocFeeds from "../children/home/components/Bloc-feeds";
import SectionGradient from "../children/home/components/SectionGradient";
import ModalInvalidKey from "../professor/home/components/ModalInvalidKey";
import ModalParentCandidate from "./components/ModalParentCandidate";
import { withTranslation, Trans } from 'react-i18next';
// import BlocFeedsforparent from  "../children/home/components/bloc-feed-parent";
import queryString from 'query-string';
import Modal from 'react-responsive-modal'
import {Button} from 'react-bootstrap'

import {forgotPassword} from "services/api";
class Parent extends Component {
  constructor(props) {
    super(props);
    const query = new URLSearchParams(props.location.search);
    var openConnexion = false;
    if(query.get('reset_password')){
      var openConnexion = true;
      var data_ = {
        key: query.get('key'),
        id: query.get('id'),
        from: 'koolskools'
      };
      if(query.get('is_email')){
        data_.is_email = true;
      }
      if(global.is_laraki_logo)
        data_.from = 'elaraki';
      else if(global.is_sanady_logo)
        data_.from = 'sanady';
      else if(global.is_pont_logo)
        data_.from = 'pont';
      else if(global.is_reference_logo)
        data_.from = 'reference';
      else if(global.is_yakada_logo)
        data_.from = 'yakada';
      else if(global.is_kastalani_logo)
        data_.from = 'kastalani';
      else if(global.is_fmps_logo)
        data_.from = 'FMPS';
      forgotPassword(data_).then(data => {
        this.setState({reset_password_response: data.response_msg, reset_password_error: data.error});
      })
      .catch(error => {
      });
    }
    this.state = {
      clientWidth: 0,
      clientHeight: 0,
      widthMobile: 0,
      heightMobile: 420,
      openConnexion:openConnexion,
      openRegister: false,
      openCandidate: false,
      openSuccess: false
    };
    this.myRef = React.createRef();
    this.RefMobile = React.createRef();
    this.params = queryString.parse(this.props.location.search);
    
  }

  updateDimensions = () => {
    this.setState({
      clientWidth: this.myRef.current.clientWidth,
      clientHeight: this.myRef.current.clientHeight,
      widthMobile: this.RefMobile.current.offsetWidth,
      heightMobile: this.RefMobile.current.offsetHeight
    });
  };
  componentDidMount() {
    this.updateDimensions();
    window.addEventListener("resize", this.updateDimensions);
    window.scrollTo(0, 0);
    if(this.props.history.location.hash=='#login'){
      this.setState({
        openConnexion:true
      })
    }
  }

  componentWillUnmount() {
    this.updateDimensions();
    window.removeEventListener("resize", this.updateDimensions);
  }

  render() {
    // console.log('props location',this.props.history.location.hash)  
    const {openCandidate, openSuccess} = this.state
    return (
      <Parents>
        {this.props.location && this.props.location.state && this.props.location.state.invalid_key &&
          <ModalInvalidKey success={true}
            handleCloseSuccess={()=>{
              const state = { ...this.props.history.location.state };
              delete state.invalid_key;
              this.props.history.replace({ ...this.props.history.location, state });
            }}
          />
        }
        <Header pages="parent"
          username={this.params.username} key_inv={this.params.key_inv} 
          email={this.params.email} 
          account_activated={this.params.account_activated && this.params.account_activated === "true"} 
          openConnexion={this.state.openConnexion}
          reset_password_response={this.state.reset_password_response}
          reset_password_error={this.state.reset_password_error}
          openRegister={this.state.openRegister}
          handleCloseConnexion={() => this.setState({openConnexion:false, openRegister: false, reset_password_response: "", reset_password_error:""})}
          handleOpenConnexion={()  => this.setState({openConnexion:true, openRegister: false})}
          handleCloseRegister={() => this.setState({openRegister:false, openConnexion:false})}
          handleOpenRegister={()  => this.setState({openRegister:true,openConnexion:false})}
          {...this.props}/>
        <section className="one" ref={this.myRef}>
          <SectionGradient
            clientWidth={this.state.clientWidth}
            clientHeight={this.state.clientHeight}
            widthMobile={this.state.widthMobile}
            heightMobile={
              this.state.heightMobile > -60 ? 420 : this.state.heightMobile
            }
          />
          <div className="container">
            <div className="row">
              <div className="col-xl-8 col-lg-12 " ref={this.RefMobile}>
                <Carousel 
                  pages="parent"
                  handleOpenModalConnexion={()  => this.setState({openConnexion:true, openRegister: false})}
                  handleOpenModalRegister={()  => this.setState({openRegister:true,openConnexion:false})}
                  handleOpenModalCandidate={()  => this.setState({openCandidate:true})}
                />
              </div>
              <div className="col-xl-4 col-lg-12 news-feed">
                <BlocFeeds pages="parent" />
              </div>
            </div>
          </div>
        </section>
        <Footer />
        <ModalParentCandidate
            open={openCandidate}
            hide={() => this.setState({openCandidate:false})}
            success={() => this.setState({openCandidate:false, openSuccess:true})}
          />
        <Modal open={openSuccess}
            onClose={() => this.setState({openSuccess:false})}
            showCloseIcon={false}
            center
            classNames={{
                overlay: {dialogOverlay: "white", successDialog: "white"},
                modal: {dialog: "white", dialogSuccess: "white"},
            }}>
            <Button variant='circle' className="btn-green closeModal" onClick={evt => this.setState({openSuccess:false})}>
              <i className="fa fa-times"></i>
            </Button>
            <div className="dialogSucces">
              <h5>{this.props.t("Nous avons bien reçu votre demande d'inscription. Nous vous contacterons dans les plus brefs délais.")}</h5>
            </div>
        </Modal>
      </Parents>
    );
  }
}

const Parents = styled.div`
  section {
    margin-bottom: 100px;
  }
  @media (max-width: 1199px) {
    section {
      margin-bottom: 150px;
    }
  }
`;

export default withTranslation('translations')(Parent);
